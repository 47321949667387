

body {
  margin: 0;
  font-family: 'Press Start 2P';
  background-color: #8fff73;
}

.header{
  position: absolute;
  width: 600px;
  height: 100px;
  left: calc(50% - 600px/2);
  font-family: 'Press Start 2P';
  font-size: 20px;
  align-items: center;
  text-align: center;
  color: #000000;
}

.main-container{
  position: absolute;
  width: 600px;
  height: 700px;
  left: calc(50% - 600px/2);
  top: 80px;
  background: #D9D9D9;
}

.top-hud{
  position: absolute;
  width: 550px;
  height: 60px;
  left: calc(50% - 550px/2);
  top: 25px;
  background: #4FC9C2;
}

.play-field{
  position: absolute;
  width: 550px;
  height: 500px;
  left: calc(50% - 550px/2);
  top: 100px;
  background: #A22020;
  border-radius: 20px;
  align-items: center;
  text-align: center;
}

.bottom-hud{
  position: absolute;
  width: 550px;
  height: 60px;
  left: calc(50% - 550px/2);
  top: 615px;
  background: #4FC9C2;
}

.loginsignupbtn{
  left: calc(50% - 125px/2);
  background: #D9D9D9;
}

button {
  position: absolute;
  width: 125px;
  height: 40px;
  background: #D9D9D9;
  font-family: 'Press Start 2P';
  cursor: pointer
}

@keyframes bgcolor {
  0% {background-color: red}
  16.6% {background-color: green}
  33.3% {background-color: orange}
  50% {background-color: blue}
  66.6% {background-color: yellow}
  83.3% {background-color: purple}
  100% {background-color: red}
}

@keyframes noanimate {
  0% {background-color: #D9D9D9}
  100% {background-color: #D9D9D9}
}

button:hover {
  animation: bgcolor;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

button:active {
  background: black;
}

.home-body {
  align-items: center;
  text-align: center;
}

.home-body button {
  left: calc(50% - 125px/2);
}

input{
  width: 300px;
  height: 30px;
  font-family: 'Press Start 2P';
  background: #D9D9D9;
}

.signup-login h3{
  font-size: 75%;
}

.scorelist{
  align-items: center;
  text-align: left;
  margin-left: 20px;
}

.button1{
  left: 10px;
  top: 10px;
}

.button2{
  left: 145px;
  top: 10px;
}

.button3{
  left: 280px;
  top: 10px;
}

.button4{
  left: 415px;
  top: 10px;
}

#empty:hover {
  animation: noanimate;
  cursor: default;
}

.item-store {
  margin: 15px 0px 0px 20px;
  height: 30px;
  align-items: left;
  text-align: left;
  font-size: 15px;
}

.storebutton {
  position: relative;
  width: 150px;
  height: 20px;
  font-size: 12px;
  vertical-align: 35%;
}

#empty-smallprint {
  font-size: 8px;
  animation: noanimate;
  cursor: default;
}

#hole1{
  position: absolute;
  left: calc(25% - 100px/2);
  top: calc(75% - 100px/2);
}

#hole2{
  position: absolute;
  left: calc(50% - 100px/2);
  top: calc(75% - 100px/2);
}

#hole3{
  position: absolute;
  left: calc(75% - 100px/2);
  top: calc(75% - 100px/2);
}

#hole4{
  position: absolute;
  left: calc(25% - 100px/2);
  top: calc(50% - 100px/2);
}

#hole5{
  position: absolute;
  left: calc(50% - 100px/2);
  top: calc(50% - 100px/2);
}

#hole6{
  position: absolute;
  left: calc(75% - 100px/2);
  top: calc(50% - 100px/2);
}

#hole7{
  position: absolute;
  left: calc(25% - 100px/2);
  top: calc(25% - 100px/2);
}

#hole8{
  position: absolute;
  left: calc(50% - 100px/2);
  top: calc(25% - 100px/2);
}

#hole9{
  position: absolute;
  left: calc(75% - 100px/2);
  top: calc(25% - 100px/2);
}

.hole{
  width: 100px;
  height: 100px;
  background: #D9D9D9;
  border-radius: 50px;
  outline: ridge black 10px;
  font-size: 20px;
}

.hole:hover{
  animation: bgcolor;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  cursor: pointer;
}

.settings-p {
  position: absolute;
  width: 500px;
  left: calc(50% - 500px/2);
}

.enemyImage{
  position: absolute;
  border-radius: 50px;
  width: 100px;
  height: 100px;
  z-index: 0;
  left: calc(50% - 100px/2);
  top: calc(50% - 100px/2);
  opacity: 70%;
}

.holeText{
  position: absolute;
  width: 100px;
  height: 100px;
  z-index: 1;
  left: calc(50% - 100px/2);
  top: calc(95% - 100px/2);
}

.modal {
  position: absolute;
  width: 500px;
  height: 150px;
  left: calc(50% - 500px/2);
  top: 150px;
  border-radius: 20px;
  align-items: center;
  text-align: center;
  z-index: 1; 
  background-color: #4FC9C2; 
}

.modal-content{
  padding-top: 10px;
}

.modal-button1{
  left: 100px;
  top: 70px;
}

.modal-button2{
  left: 275px;
  top: 70px;
}

.close {
  position: absolute;
  left: 465px;
  top: 10px;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #A22020;
  cursor: pointer;
}

.tophbutton{
  position: absolute;
  width: 200px;
  height: 200px;
  left: calc(50% - 200px/2);
  top: calc(50% - 200px/2);
  border: ridge #ffd900;
  border-width: 10px;
}

.tophbutton:hover{
  animation-duration: .5s;
}

.howtoplay {
  position: absolute;
  left: calc(50% - 500px/2);
  width: 500px;
  height: 400px;
  text-align: left;
  overflow-y: scroll;
  line-height: 1.5;
}

select {
  width: 310px;
  height: 25px;
  font-size: 12px;
  font-family: 'Press Start 2P';
  border: ridge #ffd900;
  border-width: 5px;
}

.warning{
  font-size: 12px;
}

.highscores {
  position: absolute;
  left: calc(50% - 500px/2);
  width: 500px;
  height: 400px;
  overflow-y: scroll;
  background-color: #4FC9C2;
}